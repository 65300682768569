<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
                :to="{ path: '/system_Management/system_management_home' }"
                >系统管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>岗位列表</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input
                        placeholder="请输入内容"
                        v-model="queryParams.text"
                        clearable=""
                        @clear="getRolesList"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="getRolesList"
                        ></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible = true"
                        >添加岗位</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="rolesList"
                border
                stripe
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="岗位名称" prop="name"></el-table-column>
                <el-table-column
                    label="岗位标示"
                    prop="wildcard"
                ></el-table-column>
                <el-table-column label="岗位描述" prop="desc"></el-table-column>
                <el-table-column label="操作" width="300px">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            icon="el-icon-edit"
                            size="mini"
                            @click="showEditDialog(scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="mini"
                            @click="showDeleteRoleTip(scope.row.id)"
                            >删除</el-button
                        >
                        <el-button
                            type="warning"
                            icon="el-icon-setting"
                            size="mini"
                            @click="showSetRightDialog(scope.row)"
                            >分配权限</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryParams.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryParams.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 添加岗位dialog -->
        <el-dialog
            title="添加岗位"
            :visible.sync="addDialogVisible"
            width="50%"
            @close="addDialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" :rules="addFormRules">
                <el-form-item label="岗位名称" prop="name" label-width="80px">
                    <el-input v-model="addForm.name"></el-input>
                </el-form-item>
                <el-form-item
                    label="岗位标示"
                    prop="wildcard"
                    label-width="80px"
                >
                    <el-input v-model="addForm.wildcard"></el-input>
                </el-form-item>
                <el-form-item label="岗位描述" prop="desc" label-width="80px">
                    <el-input v-model="addForm.desc"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addRole">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑岗位dialog -->
        <el-dialog
            title="编辑岗位"
            :visible.sync="editDialogVisible"
            width="50%"
            @close="editDialogClose"
        >
            <el-form ref="editFormRef" :model="editForm" :rules="editFormRules">
                <el-form-item label="岗位名称" prop="name" label-width="80px">
                    <el-input v-model="editForm.name"></el-input>
                </el-form-item>
                <el-form-item
                    label="岗位标示"
                    prop="wildcard"
                    label-width="80px"
                >
                    <el-input v-model="editForm.wildcard"></el-input>
                </el-form-item>
                <el-form-item label="岗位描述" prop="desc" label-width="80px">
                    <el-input v-model="editForm.desc"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editRole">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 分配权限Dialog -->
        <el-dialog
            title="分配权限"
            :visible.sync="setRightDialogVisible"
            width="50%"
        >
            <el-tree
                :data="rightsList"
                :props="treeProps"
                node-key="id"
                :default-checked-keys="defKeys"
                show-checkbox
                default-expand-all
                ref="treeRef"
            ></el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setRightDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="allotRights">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getRoleList, creatRoleData, editRoleData, deleteRoleinfo, getSelectMenuTreeData, saveSysMenuTreeData } from '@/http/api'
export default {
  data () {
    return {
      queryParams: {
        text: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      rolesList: [],
      addForm: {
        name: '',
        wildcard: '',
        desc: ''
      },
      addFormRules: {
        name: [
          { required: true, message: '请输入岗位名称', trigger: 'blur' }
        ],
        wildcard: [
          { required: true, message: '请输入岗位标示', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入岗位描述', trigger: 'blur' }
        ]
      },
      addDialogVisible: false,
      editDialogVisible: false,
      editForm: {},
      editFormRules: {
        name: [
          { required: true, message: '请输入岗位名称', trigger: 'blur' }
        ],
        wildcard: [
          { required: true, message: '请输入岗位标示', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入岗位描述', trigger: 'blur' }
        ]
      },
      setRightDialogVisible: false,
      rightsList: [],
      // 树形控件所绑定的属性对象
      treeProps: {
        children: 'childMenu',
        label: 'name'
      },
      // 树形控件中默认选中的数组
      defKeys: [],
      // 即将被分配权限的用户id
      roleId: ''
    }
  },
  created () {
    this.getRolesList()
  },
  methods: {
    getRolesList () {
      getRoleList(this.queryParams).then((res) => {
        this.total = res.data.total
        this.rolesList = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleSizeChange (pageSize) {
      this.queryParams.pageSize = pageSize
      this.getRolesList()
    },
    handleCurrentChange (pageNum) {
      this.queryParams.pageNum = pageNum
      this.getRolesList()
    },
    // 添加岗位点击事件
    addRole () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        creatRoleData(this.addForm).then((res) => {
          this.$message.success('添加岗位成功')
          this.addDialogVisible = false
          this.getRolesList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    addDialogClose () {
      this.$refs.addFormRef.resetFields()
    },
    showEditDialog (roleData) {
      this.editForm = roleData
      this.editDialogVisible = true
    },
    editDialogClose () {
      this.$refs.editFormRef.resetFields()
    },
    async editRole () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        editRoleData(this.editForm).then((res) => {
          this.$message.success('修改岗位信息成功')
          this.editDialogVisible = false
          this.getRolesList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    showDeleteRoleTip (id) {
      this.$confirm('此操作将永久删除该岗位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRoleById(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async deleteRoleById (id) {
      deleteRoleinfo(id).then((res) => {
        this.$message.success('删除岗位成功')
        this.getRolesList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    async removeRightById (role, rightId) {
      const confirmResult = await this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const { data: res } = await this.$http.delete('/erp/RoleManager/removeRight', {
        params: {
          roleId: role.role_id,
          menuId: rightId
        }
      })
      console.log('removeRightById', res)

      if (res.code !== 0) {
        return this.$message.error(res.message)
      }

      this.$message.success('移除权限成功')
      role.childMenu = res.data
    },
    showSetRightDialog (roleData) {
      console.log('roleData', roleData)
      this.roleId = roleData.id
      getSelectMenuTreeData({ id: roleData.id }).then((res) => {
        this.defKeys = []
        this.rightsList = res.data.tree
        // 遍历拿到所有三级权限id
        this.setRightDialogVisible = true
        this.defKeys = this.getIsSelectNodeId(this.defKeys, this.rightsList)
        console.log('this.defKeys', this.defKeys)
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 回显的时候父类被选中子类会都显示已选中，所以在这里只取子类id
    getIsSelectNodeId (expandedKeys, moduleDataList) {
      for (let i = 0; i < moduleDataList.length; i++) {
        if (moduleDataList[i].childMenu) {
          expandedKeys = this.getIsSelectNodeId(expandedKeys, moduleDataList[i].childMenu)
        } else if (!moduleDataList[i].childMenu && moduleDataList[i].isselect === true) {
          expandedKeys.push(moduleDataList[i].id)
        }
      }
      return expandedKeys
    },
    async allotRights () {
      // 获取所有树形控件中所有已选和半选的key
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys()
      ]
      saveSysMenuTreeData({ menuIdList: keys, roleId: this.roleId }).then((res) => {
        this.$message.success('岗位授权成功')
        this.setRightDialogVisible = false
        this.getRolesList()
      }).catch((err) => {
        console.log('err', err)
      })
    }
  }
}
</script>

  <style lang="less" scoped>
.el-tag {
    margin: 7px;
}

.top_bd {
    border-top: 1px solid #eee;
}

.bottom_bd {
    border-bottom: 1px solid #eee;
}

.vcenter {
    display: flex;
    align-items: center;
}
</style>
